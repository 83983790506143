import PropTypes from 'prop-types'
import React from 'react';
// import Link from 'gatsby';

class TransactionShare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trname: '',
      price: '',
      sellerName: '',
      sellerEmail: '',
      humanId:'',
      trhash: '',
      fee: 0,
      total: 0
    };
  }

  componentDidMount () {
    console.log('component TransactionEdit From mounted');
    let trhash = this.props.path.hash;

    let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-transaction-share?trhash=${trhash.substr(1)}`
    fetch(requestUrl, {
      method: 'GET', 
      mode: 'cors', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === 'success'){
        this.setState({
          trname : data.data.trname, 
          price: data.data.price,
          sellerName: data.data.sellerName,
          sellerEmail: data.data.sellerEmail,
          humanId: data.data.humanId,
          fee: data.data.fee,
          total: data.data.total,
        });
      }
      else{
        
        //window.location = "https://t.me/TrastaTestingBot"
      }
    });
  }
 
  
  render() {
    
    return (
      <header id="header" style={this.props.timeout ? { display: 'none' } : {}}>
      <div className="logo">
        <span className="icon fa-handshake-o fa-2x"></span>
      </div>
      <div className="content">
        <div className="inner">
          <p>
            <h4>Завдаток № {this.state.humanId }</h4>
          </p>
          <p>
            <strong> Товар/Послуга : {this.state.trname } </strong>
          </p>
          <p>
            <strong> Ціна {this.state.total } грн. </strong>
            <br/>
            <small> Комісія сервісу { this.state.fee } грн. </small>
          </p>
          <p>
            <a className="button" href="https://telegram.me/"><i className="fa fa-telegram"></i> Перейти </a>
          </p>
        </div>
      </div>

    </header>
    )
  }
}
TransactionShare.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}
export default TransactionShare